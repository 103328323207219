.node circle {
  cursor: pointer;
  stroke: #2e6ca4;
  stroke-width: 1.5px;
}

.node text {
  font-size: 1rem;
  color: white;
  pointer-events: none;
  text-anchor: middle;
}

line.link {
  fill: none;
  stroke: #9ecae1;
  stroke-width: 1.5px;
}
